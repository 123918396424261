body {
  -ms-overflow-style: none;  /* Internet Explorer, Edge */
  scrollbar-width: none;  /* Firefox */
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
  display: none;  /* Safari, Chrome, Opera */
}
div[class*="-MenuPortal"] {
      z-index: 9999 !important;
}
